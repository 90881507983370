const emailpattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phonepattern = /^09[0-9]{8}$/;
const monthString = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const dateString = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

class Common {
  Quality_JPEG = 0.8;
  PackageStatus_Init = 1;
  PackageStatus_WaitReceive = 2;
  PackageStatus_Received = 3;
  PackageStatus_WaitReturn = 4;
  PackageStatus_Return = 5;
  PackageStatus_Deposit_WaitReceive = 6;
  PackageStatus_Deposit_Receive = 7;

  PackageCheckMethod_DeviceCode = 1; // 手機條碼
  PackageCheckMethod_Card = 2; // NFC載具
  PackageCheckMethod_Directly = 3; // 直接領取
  PackageCheckMethod_Shipping = 4; // 物流領取
  PackageCheckMethod_Manual = 5; // 手動結單

  GuestStatus_Enter = 1; // 訪客進入
  GuestStatus_Leave = 2; // 訪客離開

  PointChangeMethod_Use_PublicUtilities = 1; // 使用公設
  PointChangeMethod_Stored = 2; // 儲值
  PointChangeMethod_TransferOut = 3; // 轉出
  PointChangeMethod_Return = 4; // 回補
  PointChangeMethod_Expired = 5; // 過期
  PointChangeMethod_Use_Other = 6; // 使用其他項目
  PointChangeMethod_Import = 7; // 匯入批次修改
  PointChangeMethod_TransferIn = 8; // 轉入
  PointChangeMethod_Reserve = 9; // 預約扣點
  PointChangeMethod_ReserveCancel = 10; // 取消預約回補

  PointType_General = 1; // 一般點數
  PointType_Cash = 2; // 現金點數

  BookingMethod_DeviceDBID = 1; // 裝置條碼
  BookingMethod_Card = 2; // 磁扣、NFC
  BookingMethod_Select = 3; // 直接選擇
  BookingMethod_APP = 4; // APP

  BookingStatus_Booking = 1; // 已預約(未進場)
  BookingStatus_BookingCancel = 2; // 預約取消
  BookingStatus_BookingEnter = 3; // 已預約(已進場)
  BookingStatus_EnterDirectly = 4; // 未預約直接進場
  BookingStatus_Timeout = 5; // 逾時未進場
  BookingStatus_Leave = 6; // 已離場

  BookingPointStatus_Prepaid = 1;
  BookingPointStatus_Paid = 2;
  BookingPointStatus_Unpaid = 3;
  BookingPointStatus_Return = 4;

  NewsStatus_NoShow = 1; // 未刊登
  NewsStatus_WaitTime = 2; // 待刊登
  NewsStatus_Showing = 3; // 刊登中
  NewsStatus_EndTime = 4; // 已下架

  GASDATA_INPUT_METHOD_STAFF = 1;
  GASDATA_INPUT_METHOD_APP = 2;

  MGMT_FEE_PAYTYPE_ATM = 1;
  MGMT_FEE_PAYTYPE_CARD = 2;
  MGMT_FEE_PAYTYPE_MOBILE = 3;
  MGMT_FEE_PAYTYPE_LINE = 4;
  MGMT_FEE_PAYTYPE_STAFF = 5;

  STORAGE_STOCK_CHANGE_MODIFY = 1; // 手動調整數量
  STORAGE_STOCK_CHANGE_TAKE = 2; // 領取
  STORAGE_STOCK_CHANGE_PUT = 3; // 存入

  SHIFT_CHECK_IN = 1; // 上班
  SHIFT_CHECK_OUT = 2; // 下班

  MAINTENANCE_REPORT_STATUS_PENDING = 1; // 待處理
  MAINTENANCE_REPORT_STATUS_DISPATCH = 2; // 已派工
  MAINTENANCE_REPORT_STATUS_NOTDISPATCH = 3; // 不派工
  MAINTENANCE_REPORT_STATUS_COMPLETED = 4; // 結案

  MAINTENANCE_CONTRACT_TYPE_FULL = 1; // 全責
  MAINTENANCE_CONTRACT_TYPE_HALF = 2; // 半責

  constructor(vue) {
    this.vue = vue;
    this.rule = new FormRule(vue);
  }

  static parseJWT(token) {
    var base64Url = token.split(".")[1] + "";
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    // console.log("Payload:", jsonPayload);
    return JSON.parse(jsonPayload);
  }

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  // validationEmail(string) {
  //   return emailpattern.test(string);
  // }

  validationPhone(string) {
    return phonepattern.test(string);
  }

  isNumeric(str) {
    if (typeof str != "string" && typeof str != "number") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  parseRFC3336toYYYYMMDD(timeString) {
    let d = new Date(timeString);
    return `${d.getFullYear()}-${monthString[d.getMonth()]}-${dateString[d.getDate() - 1]}`;
  }

  parseRFC3336toHHMMSS(timeString) {
    let d = new Date(timeString);
    return `${d.getHours() < 10 ? "0" : ""}${d.getHours()}:${d.getMinutes() < 10 ? "0" : ""}${d.getMinutes()}:${
      d.getSeconds() < 10 ? "0" : ""
    }${d.getSeconds()}`;
  }

  parseRFC3336toHHMM(timeString) {
    let d = new Date(timeString);
    return `${d.getHours() < 10 ? "0" : ""}${d.getHours()}:${d.getMinutes() < 10 ? "0" : ""}${d.getMinutes()}`;
  }

  parseRFC3336toYYYYMMDDHHMMSS(timeString) {
    let d = new Date(timeString);
    return `${d.getFullYear()}-${monthString[d.getMonth()]}-${dateString[d.getDate() - 1]} ${d.getHours() < 10 ? "0" : ""}${d.getHours()}:${
      d.getMinutes() < 10 ? "0" : ""
    }${d.getMinutes()}:${d.getSeconds() < 10 ? "0" : ""}${d.getSeconds()}`;
  }

  parseRFC3336toYYYYMMDDHHMM(timeString) {
    let d = new Date(timeString);
    return `${d.getFullYear()}-${monthString[d.getMonth()]}-${dateString[d.getDate() - 1]} ${d.getHours() < 10 ? "0" : ""}${d.getHours()}:${
      d.getMinutes() < 10 ? "0" : ""
    }${d.getMinutes()}`;
  }

  parseDatetoRFC3336(datsString, isStartTime) {
    // 2020-01-20
    let TimezoneOffset = new Date().getTimezoneOffset();
    let AbsTimezoneOffset = Math.abs(TimezoneOffset);
    let offsetHour = AbsTimezoneOffset / 60;
    let offsetMin = AbsTimezoneOffset % 60;
    return `${datsString}T${isStartTime ? "00:00:00" : "23:59:59"}${TimezoneOffset <= 0 ? "+" : "-"}${offsetHour < 10 ? "0" : ""}${offsetHour}:${
      offsetMin < 10 ? "0" : ""
    }${offsetMin}`;
  }

  stringToDate(dateString, timeString, isStartTime) {
    // 將日期字串轉換為 Date 物件
    let date = new Date(dateString);

    // 將時間字串轉換為小時和分鐘的數字
    let [hours, minutes] = timeString.split(":").map(Number);

    // 設定 Date 物件的小時和分鐘
    date.setHours(hours);
    date.setMinutes(minutes);
    if (!isStartTime) {
      date.setSeconds(59);
    }
    return date;
  }

  isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }

  getMimeFileType(fileExt) {
    switch (fileExt.toLowerCase()) {
      case "doc":
        return "application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pdf":
        return "application/pdf";
      case "ppt":
        return "application/vnd.ms-powerpoint";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
    }
    return "application/octet-stream";
  }

  detectMobile() {
    /* eslint-disable */
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  dataURItoBlob(dataURI) {
    // 将base64/URL编码数据组件转换为字符串中保存的原始二进制数据
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // 分离出mime组件
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // 将字符串的字节写入到一个typed array中
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString }); // 返回新的Blob对象，其中包含ia数组和mimeString作为type属性。
  }

  // base64ToBlob(base64, mime) {
  //   mime = mime || "";
  //   var sliceSize = 1024;
  //   var byteChars = window.atob(base64);
  //   var byteArrays = [];

  //   for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
  //     var slice = byteChars.slice(offset, offset + sliceSize);

  //     var byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     var byteArray = new Uint8Array(byteNumbers);

  //     byteArrays.push(byteArray);
  //   }

  //   return new Blob(byteArrays, { type: mime });
  // }
}

class FormRule {
  constructor(vue) {
    this.vue = vue;
  }

  validationEmail(string) {
    return emailpattern.test(string);
  }

  validationPhone(string) {
    return phonepattern.test(string);
  }

  checkRequired(value) {
    return typeof value == "number" || !!value || this.vue.$vuetify.lang.t("$vuetify.form.rule.required");
  }

  checkPhone(value) {
    return this.validationPhone(value) || "請輸入正確手機";
  }

  checkArrayRequired(value) {
    return (Array.isArray(value) && value.length > 0) || this.vue.$vuetify.lang.t("$vuetify.form.rule.required");
  }

  checkEmail(value) {
    return this.validationEmail(value) || this.vue.$vuetify.lang.t("$vuetify.form.rule.invalidEmail");
  }

  checkIsNumber(value) {
    return Number(value) > 0 || this.vue.$vuetify.lang.t("$vuetify.form.rule.number");
  }

  checkAddress(value) {
    return (!!value && value.length == 12) || this.vue.$vuetify.lang.t("$vuetify.form.rule.invalidAddress");
  }

  checkGreatedZero(value) {
    return Number(value) > 0 || this.vue.$vuetify.lang.t("$vuetify.form.rule.mustGreatedZero");
  }
}

export default Common;
