import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import "@/assets/font/font.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Common from "@/lib/common";
import APIServer from "@/lib/apiserver";

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-441ZJEY7H3" },
});
// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;

import ECharts from "vue-echarts";
import { use } from "echarts/core";
// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart, MapChart } from "echarts/charts";
import { LegendComponent, TitleComponent, GridComponent, TooltipComponent } from "echarts/components";

use([LegendComponent, TitleComponent, CanvasRenderer, LineChart, BarChart, PieChart, MapChart, GridComponent, TooltipComponent]);

// import VueQrcodeReader from "vue-qrcode-reader";
// Vue.use(VueQrcodeReader);

// import * as echarts from "echarts";
// Vue.prototype.$echarts = echarts;

// import "echarts/lib/chart/map";
// import "echarts/lib/chart/line";
// import "echarts/lib/chart/gauge";
// import "echarts/lib/component/title";
// import "echarts/lib/component/tooltip";
// import "echarts/lib/component/legend";
// import "echarts/lib/component/dataZoom";
Vue.component("v-chart", ECharts);

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
// Vue.prototype.$api = new APIServer(document.location.protocol + "//" + document.location.hostname, 8888);
if (process.env.NODE_ENV === "production") {
  Vue.prototype.$api = new APIServer("https://api.nestbutler.com.tw");
} else {
  Vue.prototype.$api = new APIServer("http://localhost:8888");
  // Vue.prototype.$api = new APIServer("http://192.168.1.109:8888");
}

router.beforeEach((to, from, next) => {
  if (!store.getters.getToken) {
    let token = window.sessionStorage.getItem("jwttoken");
    if (token) store.commit("login", token);
  }

  // if (!store.getters.getMenuStatus) {
  //   let menuStatus = window.sessionStorage.getItem("menuStatus");
  //   if (menuStatus) store.commit("setMenuStatus", JSON.parse(menuStatus));
  // }

  if (!store.getters.getSelectCommunity) {
    let selectCommunity = window.sessionStorage.getItem("selectCommunity");
    if (selectCommunity) store.commit("setSelectCommunity", JSON.parse(selectCommunity));
  }

  if (!store.getters.getUser) {
    let userData = window.sessionStorage.getItem("userData");
    if (userData) store.commit("setUser", JSON.parse(userData));
  }

  // if (!store.getters.getToken) {
  //   if (to.name !== "Login" && to.name !== "AccountLogin" && to.name !== "QRCodeLogin" && to.name !== "ScanLogin" && to.name !== "Register") {
  //     next({ name: "Login" });
  //     return;
  //   }
  // } else if (
  //   store.getters.getToken &&
  //   (to.name == "Login" || to.name == "AccountLogin" || to.name == "QRCodeLogin" || to.name == "ScanLogin" || to.name == "Register")
  // ) {
  //   if (store.getters.isAdmin) {
  //     next({ name: "AdminDashboard" });
  //     return;
  //   } else {
  //     next({ name: "MainScreen" });
  //     return;
  //   }
  // }
  if (to.meta.auth && !store.getters.getToken) {
    next({ name: "Login" });
    return;
  } else if (to.meta.auth && !store.getters.getSelectCommunity && to.name != "CommunitySelect") {
    next({ name: "CommunitySelect" });
    return;
  } else if (to.meta.admin && !store.getters.getUser.IsAdmin) {
    // next({ name: "MainScreen" });
    next({ name: "Login" });
    return;
  }

  // if (!store.getters.getSelectCommunity) {
  //   next({ name: "Login" });
  //   return;
  // }

  if (to.meta.module) {
    let isFindModule = false;
    for (let mi = 0; mi < store.getters.getSelectCommunity.Module.length; mi++) {
      if (store.getters.getSelectCommunity.Module[mi].Name == to.meta.module) {
        isFindModule = true;
        break;
      }
    }
    if (!isFindModule) {
      // next({ name: "Menu" });
      return;
    }
  }

  if (to.meta.function) {
    let isFindFunction = false;
    for (let mi = 0; mi < store.getters.getSelectCommunity.Function.length; mi++) {
      if (store.getters.getSelectCommunity.Function[mi] == to.meta.function) {
        isFindFunction = true;
        break;
      }
    }
    if (!isFindFunction) {
      // next({ name: "Menu" });
      return;
    }
  }

  // } else if (!to.meta.admin && store.getters.isAdmin) {
  //   next({ name: "AdminDashboard" });
  //   return;
  // }
  next();
});

const VueInstance = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$common = new Common(VueInstance);

// Axios 自動加入JWT認證
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.state.jwttoken) {
      config.headers["Authorization"] = `Bearer ${store.getters.getToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.resolve(err.response);
  },
);

// 異常處理
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 444:
          console.log("登入逾時");
          store.commit("logout");
          alert("登入逾時，請重新登入");
          router.push({ name: "Login" });
          break;

        case 401:
          console.log("401 權限不足");
          break;
        case 404:
          console.log("404 找不到該頁面");
          break;
        case 500:
          console.log("500 伺服器出錯");
          break;
        case 503:
          console.log("503 服務失效");
          break;
        default:
          console.log(`連接錯誤 Code:${err.response.status} `);
      }
    } else {
      console.log("連接到服務器失敗");
    }
    return Promise.resolve(err.response);
  },
);
